import { useEffect, useCallback, useState } from 'react';
import { useTranslation as useTranslationI18Next } from 'react-i18next';
import useStickyState from './useStickyState';
import { useAuth0 } from '../middleware/auth0-context';

const DEFAULT_LANGUAGE = 'en-US';

const useTranslation = () => {
	const { t, i18n } = useTranslationI18Next();
	const { user } = useAuth0();
	const [data, setData] = useState({ userLanguage: DEFAULT_LANGUAGE });
	const [userLanguage, setUserLanguage] = useStickyState(DEFAULT_LANGUAGE, `${user?.name}:ci_wbs_report_user_language`);

	const switchLanguage = useCallback((lang) => {
		i18n.changeLanguage(lang);
		setUserLanguage(lang);
	}, [i18n, setUserLanguage]);

	const initTranslation = useCallback(() => {
		if (userLanguage) {
			i18n.changeLanguage(userLanguage);
			setData({ userLanguage });
		}
	}, [i18n, userLanguage]);

	useEffect(() => {
		initTranslation();
	}, [initTranslation]);

	return {
		t,
		switchLanguage,
		userLanguage: data.userLanguage,
	};
};

export { useTranslation };
