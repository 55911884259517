import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './GoingForward.scss';

function GoingForward({ pageNumber, days }) {
	const { t } = useTranslation();

	let thisLastSectionAsks = t('pages:goingForward.thisLastSectionAsks');
	let whatWasTheBiggestThing = t('pages:goingForward.whatWasTheBiggestThing');
	let areThereAnyWellBeingGoals = t('pages:goingForward.areThereAnyWellBeingGoals');
	if (days) {
		thisLastSectionAsks = thisLastSectionAsks.replaceAll('30', days);
		whatWasTheBiggestThing = whatWasTheBiggestThing.replaceAll('30', days);
		areThereAnyWellBeingGoals = areThereAnyWellBeingGoals.replaceAll('30', days);
	}

	return (
		<article className={`going-forward ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={t('pages:goingForward.title')} />
				<div className="report-content">
					<p>{thisLastSectionAsks}</p>
					<p>{t('pages:goingForward.weWouldLikeToInviteYou')}</p>
					<div className="prompts">
						<h3>{t('pages:goingForward.aFewJournalingPrompts')}:</h3>
						<p>{t('pages:goingForward.didTheResultsOfYourWellBeing')}</p>
						<p>{whatWasTheBiggestThing}</p>
						<p>{t('pages:goingForward.whatHabitsOrPractices')}</p>
						<p>{areThereAnyWellBeingGoals}</p>
					</div>

					<div className="lines-wrapper">
						<div className="row">
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
					<p>{t('pages:goingForward.byUsingCheckingIn')}</p>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

GoingForward.propTypes = {
	pageNumber: PropTypes.number.isRequired,
};

export default GoingForward;
