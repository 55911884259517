import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import { dataShape } from '../../shapes/dataShape';
import BarChartVertical from '../../components/BarChartVertical';
import PieChartSummary from '../../components/PieChartSummary';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './ExecutiveSummary.scss';

function getMindfulnessRanges(days, personalMindfulnesses, baselineDate) {
	const firstRangeEndDay = Math.floor(days / 3); // if days 30 = 10
	const secondRangeStartDay = firstRangeEndDay + 1; // if days 30 = 11
	const secondRangeEndDay = firstRangeEndDay * 2; // if days 30 = 20
	const thirdyRangeStartDay = days - firstRangeEndDay + (days > 7 ? 1 : 0); // if days 30 = 21

	const firstRangeEnd = moment(baselineDate).add(firstRangeEndDay, 'days').endOf('day');
	const secondRangeStart = moment(baselineDate).add(secondRangeStartDay, 'days');
	const secondRangeEnd = moment(baselineDate).add(secondRangeEndDay, 'days').endOf('day');
	const thirdyRangeStart = moment(baselineDate).add(thirdyRangeStartDay, 'days');
	const thirdyRangeEnd = moment(baselineDate).add(days, 'days').endOf('day');

	function getInRange(start, end) {
		return personalMindfulnesses.filter((item) => {
			const cd = moment(item.completedDate).format('YYYYMMDD');
			return cd >= moment(start).format('YYYYMMDD') && cd <= moment(end).format('YYYYMMDD');
		});
	}

	const firstRange = getInRange(baselineDate, firstRangeEnd);
	const secondRange = getInRange(secondRangeStart, secondRangeEnd);
	const thirdRange = getInRange(thirdyRangeStart, thirdyRangeEnd);
	return { firstRange, secondRange, thirdRange };
}

function ExecutiveSummary({ data, pageNumber, days }) {
	const { t } = useTranslation();
	const { checkins, reflections, mindfulnesses } = data;
	const { originalDate: baselineDate } = data.survey.baseline.date;

	const totalAverageEnergy = Number(Number(checkins.reduce((acc, cur) => cur.energyLevel + acc, 0) / checkins.length).toFixed(1));

	const actions = {
		checkins: checkins.length,
		mindfulness: mindfulnesses.length,
		reflections: reflections.length,
	};
	const totalActions = actions.checkins + actions.mindfulness + actions.reflections;

	const differenceTotalAverageEnergy = 10 - totalAverageEnergy;
	const averageEnergy = [
		{
			id: 'average_energy',
			value: totalAverageEnergy,
			color: '#ef493e',
		},
		{
			id: 'average_energy_diff',
			value: differenceTotalAverageEnergy,
			color: 'transparent',
		},
	];

	const { firstRange, secondRange, thirdRange } = getMindfulnessRanges(days, mindfulnesses, baselineDate);

	const daysTickValues = Math.max(firstRange.length, secondRange.length, thirdRange.length);

	const x = Math.floor(days / 3);
	const rangeDays = [`1-${x}`, `${x + 1}-${x * 2}`, `${days - x + (days > 7 ? 1 : 0)}-${days}`];

	const barChartHasValue = firstRange.length + secondRange.length + thirdRange.length > 0;

	const daysBars = [
		{
			label: rangeDays[0],
			id: 'first',
			first: firstRange.length,
			color: '#F5D977',
		},
		{
			label: rangeDays[1],
			id: 'second',
			second: secondRange.length,
			color: '#ef493e',
		},
		{
			label: rangeDays[2],
			id: 'third',
			third: thirdRange.length,
			color: '#428c94',
		},
	];

	const customStyles = {
		root: {
			textAlign: 'center',
			position: 'relative',
			width: 300,
			height: 300,
		},
		overlay: {
			position: 'absolute',
			top: 0,
			right: 20,
			bottom: 20,
			left: 20,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: 36,
			fontWeight: 'bold',
			color: '#ef493e',
			textAlign: 'center',
			pointerEvents: 'none',
		},
	};

	let headerTitle = t('pages:executiveSummary.headerTitle');
	let theFollowingSectionOutlines = t('pages:executiveSummary.theFollowingSectionOutlines');

	if (days) {
		headerTitle = headerTitle.replaceAll('30', days);
		theFollowingSectionOutlines = theFollowingSectionOutlines.replaceAll('30', days);
	}

	return (
		<article className={`summary ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={headerTitle} />
				<div className="report-content">
					<div className="introduction">
						<h3>{t('pages:executiveSummary.title')}</h3>
						<p>{theFollowingSectionOutlines}</p>
					</div>
					<div className="actions">
						<div className="row">
							<div className="total-actions">
								<h1>+{totalActions}</h1>
								<h2>{t('pages:executiveSummary.totalActions')}</h2>
							</div>
						</div>
						<div className="row">
							<div className="check-ins">
								<span>{actions.checkins}</span>
								<h3>{t('pages:executiveSummary.checkIns')}</h3>
							</div>
							<div className="mindfulness">
								<span>{actions.mindfulness}</span>
								<h3>{t('pages:executiveSummary.mindfulness')}</h3>
							</div>
							<div className="reflections">
								<span>{actions.reflections}</span>
								<h3>{t('pages:executiveSummary.reflections')}</h3>
							</div>
						</div>
					</div>
					<div className="graphics">
						<div className="row">
							<div className={barChartHasValue ? 'col-half' : 'col'} style={customStyles.root}>
								<PieChartSummary data={averageEnergy} />
								<div style={customStyles.overlay}>
									<span>{totalAverageEnergy}</span>
								</div>
								<h3>{t('pages:executiveSummary.averageDaily')}</h3>
							</div>
							{barChartHasValue && (
								<div className="col-half" style={{ height: 300, width: 350 }}>
									<BarChartVertical data={daysBars} axisBottomLegend={t('components:other.days')} minValue={0} maxValue={daysTickValues} gridYValues={daysTickValues} tickValues={daysTickValues} />
									<h3>{t('pages:executiveSummary.completedExercises')}</h3>
								</div>
							)}
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

ExecutiveSummary.propTypes = {
	data: dataShape.isRequired,
	pageNumber: PropTypes.number.isRequired,
};

export default ExecutiveSummary;
