import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { checkingIn } from '../../utils/charts/theme';

const BarChart = ({
	data, marginLeft, marginTop, scaleOnBottom,
}) => {
	const axisScale = {
		format: value => Number(value) + 1,
		orient: 'bottom',
		tickSize: 0,
		tickPadding: 0,
		tickRotation: 0,
		tickValues: ['0', '1', '2', '3', '4'],
	};

	const configBarChart = {
		layout: 'horizontal',
		padding: 0.3,
		data: data.map((d) => {
			const valueScaled = d[d.id] - 1;
			return {
				[d.id]: valueScaled,
				label: d.label,
			};
		}),
		keys: data.reduce((acc, cur) => {
			acc.push(cur.id);
			return acc;
		}, []),
		isInteractive: false,
		indexBy: 'label',
		margin: {
			top: marginTop,
			right: 20,
			bottom: 50,
			left: marginLeft,
		},
		maxValue: 4,
		valueScale: { type: 'linear' },
		enableLabel: false,
		enableGridX: true,
		enableGridY: false,
		gridXValues: 4,
		axisLeft: {
			tickSize: 0,
			tickPadding: 5,
			tickRotation: 0,
		},
		axisRight: null,
		axisBottom: (scaleOnBottom) ? axisScale : null,
		axisTop: (!scaleOnBottom) ? axisScale : null,
		colors: (bar) => {
			const { color } = data.find(d => d.id === bar.id);
			return color;
		},
	};

	return (
		<ResponsiveBar
			{...configBarChart}
			theme={checkingIn}
		/>
	);
};

BarChart.defaultProps = {
	marginTop: 50,
	scaleOnBottom: true,
};

BarChart.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	marginLeft: PropTypes.number.isRequired,
	marginTop: PropTypes.number,
	scaleOnBottom: PropTypes.bool,
};

export default BarChart;
