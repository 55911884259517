import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from '../../hooks/useTranslation';
import { checkinShape } from '../../shapes/ckeckinShape';

const PieChartTimeCheckins = ({ checkins }) => {
	const { t } = useTranslation();

	const validCheckinsArray = Object.values(checkins);

	const eveningCheckins = validCheckinsArray.reduce((acc, c) => (moment(c.createdDate).hour() >= 18 ? ++acc : acc), 0);
	const eveningTotalEnergy = validCheckinsArray.reduce((acc, c) => (moment(c.createdDate).hour() >= 18 ? acc + c.energyLevel : acc), 0);

	const afternoonCheckins = validCheckinsArray.reduce((acc, c) => (moment(c.createdDate).hour() >= 12 && moment(c.createdDate).hour() < 18 ? ++acc : acc), 0);
	const afternoonTotalEnergy = validCheckinsArray.reduce((acc, c) => (moment(c.createdDate).hour() >= 12 && moment(c.createdDate).hour() < 18 ? acc + c.energyLevel : acc), 0);

	const morningCheckins = validCheckinsArray.reduce((acc, c) => (moment(c.createdDate).hour() < 12 ? ++acc : acc), 0);
	const morningTotalEnergy = validCheckinsArray.reduce((acc, c) => (moment(c.createdDate).hour() < 12 ? acc + c.energyLevel : acc), 0);

	const pieChartDataset = [
		{
			id: 'Morning',
			label: t('components:pieChartTimeCheckins.morning'),
			value: morningCheckins,
			averageEnergy: (morningTotalEnergy / morningCheckins).toFixed(1),
			color: '#edbe1c',
			percentage: ((morningCheckins / validCheckinsArray.length) * 100).toFixed(1),
			time: '00:00 - 11:59',
		},
		{
			id: 'Mid-Day',
			label: t('components:pieChartTimeCheckins.midDay'),
			value: afternoonCheckins,
			averageEnergy: (afternoonTotalEnergy / afternoonCheckins).toFixed(1),
			color: '#428c94',
			percentage: ((afternoonCheckins / validCheckinsArray.length) * 100).toFixed(1),
			time: '12:00 - 17:59',
		},
		{
			id: 'Evening',
			label: t('components:pieChartTimeCheckins.evening'),
			value: eveningCheckins,
			averageEnergy: (eveningTotalEnergy / eveningCheckins).toFixed(1),
			color: '#ef493e',
			percentage: ((eveningCheckins / validCheckinsArray.length) * 100).toFixed(1),
			time: '18:00 - 23:59',
		},
	];
	return (
		<ResponsivePie
			padding={0.3}
			data={pieChartDataset}
			margin={{
				top: 40,
				right: 90,
				bottom: 80,
				left: 90,
			}}
			enableRadialLabels
			sliceLabelsRadiusOffset={0.70}
			sliceLabelsTextColor="#FFFFFF"
			sliceLabel={item => `${item.data.percentage}%`}
			colors={(bar) => {
				const { color } = pieChartDataset.find(d => d.id === bar.id);
				return color;
			}}
			legends={[
				{
					anchor: 'bottom',
					direction: 'row',
					justify: false,
					translateX: 22,
					translateY: 56,
					itemsSpacing: 0,
					itemWidth: 100,
					itemHeight: 18,
					itemDirection: 'left-to-right',
					itemOpacity: 1,
					symbolSize: 19,
					symbolShape: 'circle',
					itemTextColor: '#eee',
				},
			]}
			theme={{
				labels: {
					text: {
						fontWeight: 'bold',
						fontSize: 10,
					},
				},
			}}
			radialLabelsSkipAngle={10}
			radialLabelsTextXOffset={0}
			radialLabelsLinkOffset={0}
			radialLabelsLinkDiagonalLength={8}
			radialLabelsLinkHorizontalLength={0}
			radialLabelsLinkStrokeWidth={0}
			radialLabelsTextColor={{ from: 'color' }}
			radialLabelsLinkColor={{ from: 'color' }}
			animate
			motionStiffness={90}
			motionDamping={15}
			radialLabel={d => (
				<>
					<tspan x="0" dy="-0.6em" fontSize="1.25em">
						{d.value} {d.value === 1 ? t('common:checkIn') : t('common:checkIns')}
					</tspan>
					<tspan x="0" dy="1.2em" fontSize="1em">
						{t('components:other.avgEnergy')}: {d.data.averageEnergy}
					</tspan>
				</>
			)}
		/>
	);
};

PieChartTimeCheckins.propTypes = {
	checkins: PropTypes.arrayOf(checkinShape).isRequired,
};

export default PieChartTimeCheckins;
