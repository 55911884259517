import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import { dataShape } from '../../shapes/dataShape';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { CONSTANTS } from '../../constants';
import OneLineComparison from '../../components/OneLineComparison';
import './LifeSatisfaction.scss';

function LifeSatisfaction({ data }) {
	const { t } = useTranslation();
	const { key, color, pageNumber } = CONSTANTS.LIFE_SATISFACTION;

	return (
		<article className={`life-satisfaction ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={t('pages:lifeSatisfaction.title')} color={color} />
				<div className="report-content">
					<OneLineComparison data={data} section={key} />
					<div className="overview">
						<h3>{t('common:overview')}</h3>
						<p>
							{t('pages:lifeSatisfaction.lifeSatisfactionWasMeasured')}
						</p>
						<p>
							{t('pages:lifeSatisfaction.anImportantAspectOfLifeSatisfaction')}
						</p>
					</div>
				</div>
				<div className="bottom">
					<div className="row">
						<div className="col-half">
							<h3>{t('pages:lifeSatisfaction.checkingInAndLifeSatisfaction')}</h3>
							<p>
								{t('pages:lifeSatisfaction.checkingInHelpsUsersMonitor')}
							</p>
						</div>
						<div className="col-half reflection">
							<h3>{t('common:reflection')}</h3>
							<p>
								{t('pages:lifeSatisfaction.thinkBackOnTheLast30Days')}
							</p>
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

LifeSatisfaction.propTypes = {
	data: dataShape.isRequired,
};

export default LifeSatisfaction;
