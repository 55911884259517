import PropTypes from 'prop-types';

export const userShape = PropTypes.shape({
	email: PropTypes.string.isRequired,
	email_verified: PropTypes.bool.isRequired,
	'https://web.checkingin.co/app_metadata': PropTypes.object,
	'https://web.checkingin.co/challengeStartDate': PropTypes.string,
	'https://web.checkingin.co/geoip': PropTypes.object,
	'https://web.checkingin.co/mailchimpId': PropTypes.string,
	'https://web.checkingin.co/tags': PropTypes.array,
	name: PropTypes.string,
	nickname: PropTypes.string,
	picture: PropTypes.string,
	sub: PropTypes.string,
	updated_at: PropTypes.string,
});
