import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import { dataShape } from '../../shapes/dataShape';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './Mindfulness.scss';
import { CONSTANTS } from '../../constants';
import OneLineComparison from '../../components/OneLineComparison';

function Mindfulness({ data }) {
	const { t } = useTranslation();
	const { key, color, pageNumber } = CONSTANTS.MINDFULNESS;

	return (
		<article className={`mindfulness ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={t('pages:mindfulness.title')} color={color} />
				<div className="report-content">
					<OneLineComparison data={data} section={key} />
					<div className="overview">
						<h3>{t('common:overview')}</h3>
						<p>
							{t('pages:mindfulness.mindfulnessWasMeasured')}
						</p>
						<p>
							{t('pages:mindfulness.aStudyByBrown')}
						</p>
					</div>
				</div>
				<div className="bottom">
					<div className="row">
						<div className="col-half">
							<h3>{t('pages:mindfulness.checkingInAndMindfulness')}</h3>
							<p>
								{t('pages:mindfulness.checkingInWasDesigned')}
							</p>
						</div>
						<div className="col-half reflection">
							<h3>{t('common:reflection')}</h3>
							<p>
								{t('pages:mindfulness.throughoutThe30DayChallenge')}
							</p>
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

Mindfulness.propTypes = {
	data: dataShape.isRequired,
};

export default Mindfulness;
