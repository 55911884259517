import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { checkingIn } from '../../utils/charts/theme';

const BarChartVertical = ({
	data, minValue, maxValue, axisBottomLegend, axisLeftLegend, gridYValues, tickValues,
}) => {
	const configBarChart = {
		isInteractive: false,
		layout: 'vertical',
		padding: 0.3,
		data: data.map((d) => {
			const valueScaled = d[d.id];
			return {
				[d.id]: valueScaled,
				label: d.label,
			};
		}),
		keys: data.reduce((acc, cur) => {
			acc.push(cur.id);
			return acc;
		}, []),
		indexBy: 'label',
		margin: {
			top: 20,
			right: 20,
			bottom: 70,
			left: 50,
		},
		minValue,
		maxValue,
		valueScale: { type: 'linear' },
		enableLabel: false,
		enableGridX: false,
		enableGridY: true,
		gridYValues,
		labelSkipWidth: 2,
		labelSkipHeight: 11,
		axisBottom: {
			tickSize: 0,
			tickPadding: 5,
			tickRotation: 0,
			legend: axisBottomLegend,
			legendPosition: 'middle',
			legendOffset: 35,
		},
		axisLeft: {
			tickSize: 0,
			tickPadding: 5,
			tickRotation: 0,
			tickValues,
			legend: axisLeftLegend,
			legendPosition: 'middle',
			legendOffset: -35,
		},
		axisTop: null,
		axisRight: null,
		colors: (bar) => {
			const { color } = data.find(d => d.id === bar.id);
			return color;
		},
	};

	return (
		<ResponsiveBar
			{...configBarChart}
			theme={checkingIn}
		/>
	);
};

BarChartVertical.defaultProps = {
	axisBottomLegend: '',
	axisLeftLegend: '',
};

BarChartVertical.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	minValue: PropTypes.number.isRequired,
	maxValue: PropTypes.number.isRequired,
	axisBottomLegend: PropTypes.string,
	axisLeftLegend: PropTypes.string,
	gridYValues: PropTypes.number.isRequired,
	tickValues: PropTypes.number.isRequired,
};

export default BarChartVertical;
