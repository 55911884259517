import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dateEn from 'date-fns/locale/en-US';
import dateFr from 'date-fns/locale/fr-CA';
import en from './locales/en.json';
import fr from './locales/fr-ca.json';

export const availableLanguages = [
	{ name: 'English', code: 'en-US', date: dateEn },
	{ name: 'French', code: 'fr-CA', date: dateFr },
];

export const defaultNS = 'en-US';
export const resources = {
	'en-US': en,
	'fr-CA': fr,
};

export const getLocaleDateFile = () => {
	const currentLanguage = i18n.language;
	const lang = availableLanguages.find(lng => lng.code === currentLanguage);
	if (lang) {
		return lang.date;
	}
	return dateEn;
};

i18n.use(initReactI18next).init({
	lng: navigator.language,
	ns: 'en-US',
	fallbackLng: 'en-US',
	defaultNS,
	resources,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
