import React from 'react';
import PropTypes from 'prop-types';
import './Header.scss';

function Header({ title, color }) {
	return (
		<div className="header" style={{ backgroundColor: color }}>
			<h1>{title}</h1>
		</div>
	);
}

Header.defaultProps = {
	color: '#428c94',
};

Header.propTypes = { title: PropTypes.string.isRequired, color: PropTypes.string };

export default Header;
