import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';

function CardHeader({
	title, href, titleStyle, badge,
}) {
	const { t } = useTranslation();

	return (
		<div className="card-header">
			{href && (
				<a className="carousel-control-prev" href={href} role="button" data-slide="prev">
					<span className="carousel-control-prev-icon" aria-hidden="true" />
					<span className="sr-only">{t('components:cardHeader.previous')}</span>
				</a>
			)}
			<p className={titleStyle}>
				{title}
				{badge && <span className="badge badge-light">{badge}</span>}
			</p>
		</div>
	);
}

CardHeader.defaultProps = {
	title: '',
	href: '',
	titleStyle: '',
	badge: '',
};

CardHeader.propTypes = {
	title: PropTypes.string,
	href: PropTypes.string,
	titleStyle: PropTypes.string,
	badge: PropTypes.string,
};

export default CardHeader;
