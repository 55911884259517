import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import { dataShape } from '../../shapes/dataShape';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PieChartTimeCheckins from '../../components/PieChartTimeCheckins';
import AverageEnergyBarChart from '../../components/AverageEnergy';
import './EnergyLevel.scss';

function getDays(checkins) {
	function average(nums) {
		const avg = nums.reduce((a, b) => a + b.energy, 0);
		return (avg / nums.length).toFixed(1);
	}

	const createdDateAll = checkins.map((c) => ({ date: c.localDate, energy: c.energyLevel }));
	createdDateAll.reverse();

	const sorted = {};
	for (let i = 0, max = createdDateAll.length; i < max; i++) {
		if (sorted[createdDateAll[i].date] === undefined) {
			sorted[createdDateAll[i].date] = [];
		}
		sorted[createdDateAll[i].date].push(createdDateAll[i]);
	}

	let day = 0;
	const days = Object.keys(sorted).map((key) => {
		const avg = average(sorted[key]);
		const energy = sorted[key].map((d) => d.energy);
		day++;
		return {
			day,
			label: `Day ${day}`,
			date: key,
			avg,
			energy,
		};
	});
	return days;
}

function EnergyLevel({ data, pageNumber, days }) {
	const { t } = useTranslation();
	const checkinDays = getDays(data.checkins);
	const { originalDate: baselineDate } = data.survey.baseline.date;
	const challengeStart = baselineDate.toString();

	const customStyles = {
		root: {
			textAlign: 'center',
			margin: '0 auto',
			width: 300,
			height: 300,
		},
	};

	function average2(nums) {
		const avg = nums.reduce((a, b) => Number(a) + Number(b), 0);
		return (avg / nums.length).toFixed(1);
	}

	const allAvg = checkinDays.map((d) => d.avg);
	let allAvgPage = average2(allAvg);
	allAvgPage = allAvgPage === 'NaN' ? 'N/A' : allAvgPage;

	let title = t('pages:energyLevel.title');
	let theFigureBelowShowsTheAverageDailyEnergy = t('pages:energyLevel.theFigureBelowShowsTheAverageDailyEnergy');

	if (days) {
		title = title.replaceAll('30', days);
		theFigureBelowShowsTheAverageDailyEnergy = theFigureBelowShowsTheAverageDailyEnergy.replaceAll('30', days);
	}

	return (
		<article className={`energy-level ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={title} />
				<div className="report-content">
					<div className="introduction">
						<div className="row">
							<div>
								<h3>{t('pages:energyLevel.averageDailyEnergyLevel')}</h3>
								<p>{theFigureBelowShowsTheAverageDailyEnergy}</p>
								<p>
									<span>{t('common:reflection')}:</span> {t('pages:energyLevel.thisGraphMayHelpYouToIsolate')}
								</p>
							</div>
						</div>
						<div className="row" style={{ height: 300, width: 750 }}>
							<p className="avg-daily">
								{t('pages:energyLevel.averageDailyEnergy')}: <span>{allAvgPage}</span>
							</p>
							<AverageEnergyBarChart checkinDays={checkinDays} days={days} challengeStart={challengeStart} />
						</div>
					</div>
				</div>
				<div className="bottom">
					<div className="row">
						<div className="col-half" style={customStyles.root}>
							<PieChartTimeCheckins checkins={data.checkins} />
						</div>
						<div className="col-half">
							<h3>{t('pages:energyLevel.timeOfCheckIns')}</h3>
							<p>{t('pages:energyLevel.theFigureToTheLeftRepresents')}</p>
							<p>
								<span>{t('common:reflection')}:</span> {t('pages:energyLevel.doYouNoticeWhenYouAreCheckingIn')}
							</p>
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

EnergyLevel.propTypes = {
	data: dataShape.isRequired,
	pageNumber: PropTypes.number.isRequired,
};

export default EnergyLevel;
