export function calculateDelta(baseline, exit) {
	return baseline.map((d, i) => {
		const delta = Number(Number(exit[i].value - d.value).toFixed(2));
		const section = d.id;
		return {
			id: section,
			[section]: delta,
			value: delta,
			label: d.label,
			color: d.color,
		};
	});
}
