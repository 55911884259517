import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import * as Sentry from '@sentry/browser';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import ApolloClient from 'apollo-boost';
import { I18nextProvider } from 'react-i18next';
import { Auth0Provider } from './middleware/auth0-context';

import Routes from './routes';
import ErrorBoundary from './components/ErrorBoundary';

import i18n from './i18n';

import './App.scss';

library.add(fal);

export default function App() {
	if (process.env.REACT_APP_SENTRY_URL) {
		Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
	}

	const cache = new InMemoryCache();

	// await before instantiating ApolloClient, else queries might run before the cache is persisted
	persistCache({
		cache,
		storage: window.sessionStorage,
		debug: false,
	})
		.then(() => {
			// client.writeData({ data: { activeModal: false } });
		})
		.catch((err) => {
			console.warn('persistCache error:', err);
			window.sessionStorage.removeItem('apollo-cache-persist');
		});

	const client = new ApolloClient({
		cache,
		uri: process.env.REACT_APP_GRAPHQL_URI,
		request: (operation) => {
			const token = sessionStorage.getItem('CheckingIn.token');
			operation.setContext({
				headers: {
					authorization: token ? `${token}` : '',
				},
			});
		},
	});

	localStorage.removeItem('CheckingIn.sendDailyReminder14Days_manual');

	return (
		<ErrorBoundary>
			<Auth0Provider>
				<ApolloProvider client={client}>
					<I18nextProvider i18n={i18n}>
						<Routes />
					</I18nextProvider>
				</ApolloProvider>
			</Auth0Provider>
		</ErrorBoundary>
	);
}
