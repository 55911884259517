import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import { dataShape } from '../../shapes/dataShape';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BarChartVertical from '../../components/BarChartVertical';
import './MeditationsReflections.scss';

function getReflectionsRanges(days, personalReflections, baselineDate) {
	const firstRangeEndDay = Math.floor(days / 3); // if days 30 = 10
	const secondRangeStartDay = firstRangeEndDay + 1; // if days 30 = 11
	const secondRangeEndDay = firstRangeEndDay * 2; // if days 30 = 20
	const thirdyRangeStartDay = days - firstRangeEndDay + (days > 7 ? 1 : 0); // if days 30 = 21

	const firstRangeEnd = moment(baselineDate).add(firstRangeEndDay, 'days').endOf('day');
	const secondRangeStart = moment(baselineDate).add(secondRangeStartDay, 'days');
	const secondRangeEnd = moment(baselineDate).add(secondRangeEndDay, 'days').endOf('day');
	const thirdyRangeStart = moment(baselineDate).add(thirdyRangeStartDay, 'days');
	const thirdyRangeEnd = moment(baselineDate).add(days, 'days').endOf('day');

	function getInRange(start, end) {
		return personalReflections.filter((item) => {
			const cd = moment(item.unlockDate).format('YYYYMMDD');
			return cd >= moment(start).format('YYYYMMDD') && cd <= moment(end).format('YYYYMMDD');
		});
	}

	const firstRange = getInRange(baselineDate, firstRangeEnd);
	const secondRange = getInRange(secondRangeStart, secondRangeEnd);
	const thirdRange = getInRange(thirdyRangeStart, thirdyRangeEnd);

	return { firstRange, secondRange, thirdRange };
}

const getMindfulnessNumbers = (mindfulnesses) => {
	return {
		reading: mindfulnesses.filter((m) => m.contentExercise.type === 'read'),
		breathing: mindfulnesses.filter((m) => m.contentExercise.type === 'breathe'),
		listening: mindfulnesses.filter((m) => m.contentExercise.type === 'listen'),
		moving: mindfulnesses.filter((m) => m.contentExercise.type === 'move'),
	};
};

function MeditationsReflections({ data, pageNumber, days }) {
	const { t } = useTranslation();
	const { mindfulnesses, reflections } = data;
	const { originalDate: baselineDate } = data.survey.baseline.date;

	const { firstRange, secondRange, thirdRange } = getReflectionsRanges(days, reflections, baselineDate);

	const mindfulnessNumbers = getMindfulnessNumbers(mindfulnesses);

	const completedMeditationsTickValue = Math.max(mindfulnessNumbers.reading.length, mindfulnessNumbers.breathing.length, mindfulnessNumbers.listening.length, mindfulnessNumbers.moving.length);

	const completedMeditations = [
		{
			label: t('components:other.reading'),
			id: 'reading',
			reading: mindfulnessNumbers.reading.length,
			color: '#edbe1c',
		},
		{
			label: t('components:other.breathing'),
			id: 'breathing',
			breathing: mindfulnessNumbers.breathing.length,
			color: '#ef493e',
		},
		{
			label: t('components:other.listening'),
			id: 'listening',
			listening: mindfulnessNumbers.listening.length,
			color: '#428c94',
		},
		{
			label: t('components:other.moving'),
			id: 'moving',
			moving: mindfulnessNumbers.moving.length,
			color: '#82c55c',
		},
	];

	const completedReflectionsTickValue = Math.max(firstRange.length, secondRange.length, thirdRange.length);

	const x = Math.floor(days / 3);
	const rangeDays = [`1-${x}`, `${x + 1}-${x * 2}`, `${days - x + (days > 7 ? 1 : 0)}-${days}`];

	const completedReflections = [
		{
			label: rangeDays[0],
			id: 'first',
			first: firstRange.length,
			color: '#edbe1c',
		},
		{
			label: rangeDays[1],
			id: 'second',
			second: secondRange.length,
			color: '#ef493e',
		},
		{
			label: rangeDays[2],
			id: 'third',
			third: thirdRange.length,
			color: '#428c94',
		},
	];

	let headerTitle = t('pages:meditationsReflections.headerTitle');
	let theFigureOnTheRightShows = t('pages:meditationsReflections.theFigureOnTheRightShows');
	let theFigureOnTheLeft = t('pages:meditationsReflections.theFigureOnTheLeft');

	if (days) {
		headerTitle = headerTitle.replaceAll('30', days);
		theFigureOnTheRightShows = theFigureOnTheRightShows.replaceAll('30', days);
		theFigureOnTheLeft = theFigureOnTheLeft.replaceAll('30', days);
		theFigureOnTheLeft = theFigureOnTheLeft.replaceAll('10', x);
		theFigureOnTheLeft = theFigureOnTheLeft.replaceAll('32', Number(days) + 2);
	}

	return (
		<article className={`meditations-reflections ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={headerTitle} />
				<div className="report-content">
					<div className="introduction">
						<div className="row">
							<div className="col-half">
								<div>
									<h3>{t('pages:meditationsReflections.title')}</h3>
									<p>{theFigureOnTheRightShows}</p>
									<p>
										<span>{t('common:reflection')}:</span> : {t('pages:meditationsReflections.didYouFindTheMindfulnessExercises')}
									</p>
								</div>
							</div>
							<div className="col-half" style={{ height: 300, width: 300 }}>
								<p className="chart-title">{t('pages:meditationsReflections.completedMeditations')}</p>
								<BarChartVertical
									data={completedMeditations}
									axisBottomLegend={t('components:other.meditations')}
									axisLeftLegend={t('components:other.completed')}
									minValue={0}
									maxValue={completedMeditationsTickValue}
									gridYValues={completedMeditationsTickValue}
									tickValues={completedMeditationsTickValue}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom">
					<div className="row">
						<div className="col-half" style={{ height: 300, width: 300 }}>
							<p className="chart-title">{t('pages:meditationsReflections.completedReflections')}</p>
							<BarChartVertical
								data={completedReflections}
								axisBottomLegend={t('components:other.days')}
								axisLeftLegend={t('components:other.completed')}
								minValue={0}
								maxValue={completedReflectionsTickValue}
								gridYValues={completedReflectionsTickValue}
								tickValues={completedReflectionsTickValue}
							/>
						</div>
						<div className="col-half">
							<h3>{t('pages:meditationsReflections.dailyReflections')}</h3>
							<p>{theFigureOnTheLeft}</p>
							<p>
								<span>{t('common:reflection')}:</span> {t('pages:meditationsReflections.didYouFindDailyReflecting')}
							</p>
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

MeditationsReflections.propTypes = {
	data: dataShape.isRequired,
	pageNumber: PropTypes.number.isRequired,
};

export default MeditationsReflections;
