import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { dataShape } from '../../shapes/dataShape';
import { CONSTANTS } from '../../constants';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import OneLineComparison from '../../components/OneLineComparison';
import i18n from '../../i18n';
import './WellBeing.scss';

function WellBeing({ data }) {
	const { t } = useTranslation();
	const { key, color, pageNumber } = CONSTANTS.WELL_BEING;

	return (
		<article className={`well-being ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={t('pages:wellBeing.title')} color={color} />
				<div className="report-content">
					<OneLineComparison data={data} section={key} />
					<div className="overview">
						<h3>{t('common:overview')}</h3>
						<p>
							{t('pages:wellBeing.wellBeingWasMeasured')}
						</p>
						<p>
							{t('pages:wellBeing.usingThisMeasure')}
						</p>
						<p>
							{t('pages:wellBeing.variousStudiesSuggest')}
						</p>
					</div>
				</div>
				<div className="bottom">
					<div className="row">
						<div className="col-half">
							<h3>{t('pages:wellBeing.checkingInAndWellBeing')}</h3>
							<p>
								{t('pages:wellBeing.checkingInWasCreatedToHelp')}
							</p>
							<p>
								{t('pages:wellBeing.theActOfCheckingInHelps')}
							</p>
						</div>
						<div className="col-half reflection">
							<h3>{t('common:reflection')}</h3>
							<p>
								{t('pages:wellBeing.considerHowYourOwnWellBeing')}
							</p>
							<p>{t('pages:wellBeing.whatDoYouBelieveCouldHelp')}</p>
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

WellBeing.propTypes = {
	data: dataShape.isRequired,
};

export default WellBeing;
