export const CONSTANTS = {
	WELL_BEING: {
		key: 'well_being',
		label: 'constants:wellBeing',
		color: '#EDBE1C',
		pageNumber: 5,
	},
	LIFE_MEANING_HAPPINESS: {
		key: 'life_meaning_happiness',
		label: 'constants:lifeMeaningHappiness',
		color: '#626290',
		pageNumber: 6,
	},
	LIFE_SATISFACTION: {
		key: 'life_satisfaction',
		label: 'constants:lifeSatisfaction',
		color: '#EDBE1C',
		pageNumber: 7,
	},
	LIFE_ENGAGEMENT: {
		key: 'life_engagement',
		label: 'constants:lifeEngagement',
		color: '#B94E8B',
		pageNumber: 8,
	},
	SELF_REFLECTION: {
		key: 'self_reflection',
		label: 'constants:selfReflection',
		color: '#428C94',
		pageNumber: 9,
	},
	MINDFULNESS: {
		key: 'mindfulness',
		label: 'constants:mindfulness',
		color: '#EF4165',
		pageNumber: 10,
	},
	SELF_INSIGHT: {
		key: 'self_insight',
		label: 'constants:selfInsight',
		color: '#82C55C',
	},
	LIFE_MEANING: {
		key: 'life_meaning',
		label: 'constants:lifeMeaning',
		color: '#F37223',
	},
	LIFE_HAPPINESS: {
		key: 'life_happiness',
		label: 'constants:lifeHappiness',
		color: '#626290',
	},
	// CHECKINGIN_ENGAGEMENT: {
	// 	key: 'checkingin_engagement',
	// 	label: 'constants:checkingInEngagement',
	// 	color: '#626290',
	// },
	// DEMOGRAPHICS: {
	// 	key: 'demographics',
	// 	label: 'constants:demographics',
	// 	color: '#626290',
	// },
};
