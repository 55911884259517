import React from 'react';
import PropTypes from 'prop-types';
import ReactWordcloud from 'react-wordcloud';

function WordCloud({ words }) {
	return (
		<ReactWordcloud
			words={words}
			options={{
				colors: ['#b94e8b', '#ef493e', '#F37223', '#428c94'],
				fontSizes: [16, 50],
				padding: 2,
				rotations: 1,
				rotationAngles: [0],
				scale: 'log',
				spiral: 'rectangular',
				transitionDuration: 0
			}}
			size={[350, 350]}
		/>
	);
}

WordCloud.propTypes = {
	words: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string,
		value: PropTypes.number,
	})).isRequired,
};

export default WordCloud;
