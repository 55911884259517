import { format } from 'date-fns';
import { getLocaleDateFile } from '../../i18n';

export function ReportDate(date) {
	return {
		originalDate: date,
		longDate: format(new Date(date), 'LLLL dd, yyyy', {
			locale: getLocaleDateFile(),
		}),
		shortDate: format(new Date(date), 'LLL dd, yyyy', {
			locale: getLocaleDateFile(),
		}),
	};
}
