import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFound from '../components/NotFound';
import Spinner from '../components/Spinner';
import { useAuth0 } from '../middleware/auth0-context';
import Report from '../pages/Report';
import ReportNoSurvey from '../pages/ReportNoSurvey';
import ReportSelector from '../pages/ReportSelector';

function Routes() {
	const { isLoading, user, loginWithRedirect, logout } = useAuth0();

	if (isLoading) {
		return <Spinner />;
	}

	if (!user) return loginWithRedirect();

	return (
		<Router>
			<Switch>
				<Route exact path="/report">
					<Report user={user} />
				</Route>
				<Route exact path="/challengereport/:days/:challengeStartDate?">
					<ReportNoSurvey user={user} />
				</Route>
				<Route
					exact
					path="/logout"
					render={() => {
						logout();
						return null;
					}}
				/>
				<Route path="/selector">
					<ReportSelector />
				</Route>
				<Route component={NotFound} />
			</Switch>
		</Router>
	);
}

export default Routes;
