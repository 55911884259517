import { useQuery, useMutation } from '@apollo/react-hooks';

import userGraph from '../../graphs/user';

export function useUpdateFirstName() {
	const [updateUser] = useMutation(userGraph.UPDATE_USER);

	const updateName = (newName) => {
		updateUser({
			variables: { request: { name: newName } },
		});
	};
	return { updateName };
}

export function useUser(timezone) {
	const { loading, error, data } = useQuery(userGraph.GET_USER, {
		variables: { request: { timezone } },
		fetchPolicy: 'cache-and-network',
	});

	return { loading, data, error };
}

export function useImpersonatedUser(timezone, email) {
	const { loading, error, data } = useQuery(userGraph.GET_IMPERSONATED_USER, {
		variables: { request: { timezone, email } },
		fetchPolicy: 'cache-and-network',
	});

	return { loading, data, error };
}

export function useUserSettings() {
	const { loading, data, error } = useQuery(userGraph.GET_USER_SETTINGS, {
		fetchPolicy: 'no-cache',
	});
	const userSettings = data && data.userSetting;
	return { loading, userSettings, error };
}
