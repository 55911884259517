const emotionList = {
	angry: [
		'abrasive',
		'accused',
		'aggressive',
		'agitated',
		'amped up',
		'angry',
		'annoyed',
		'appalled',
		'argumentative',
		'averse',
		'belligerent',
		'betrayed',
		'bitter',
		'brooding',
		'cheated',
		'closed',
		'cold',
		'combative',
		'contemptuous',
		'crabby',
		'critical',
		'crusty',
		'cut-off',
		'defensive',
		'defiant',
		'destructive',
		'disapproving',
		'disgruntled',
		'disgusted',
		'disrespected',
		'elevated',
		'enraged',
		'envious',
		'exasperated',
		'explosive',
		'feisty',
		'fierce',
		'fired up',
		'frustrated',
		'fuming',
		'furious',
		'harsh',
		'hateful',
		'heightened',
		'hostile',
		'hysterical',
		'incensed',
		'indignant',
		'infuriated',
		'insolent',
		'irate',
		'irritable',
		'irritated',
		'jealous',
		'judgemental',
		'lippy',
		'livid',
		'loathing',
		'mad',
		'manipulated',
		'mean',
		'merciless',
		'moody',
		'negative',
		'on edge',
		'outraged',
		'pissed off',
		'possessive',
		'prejudiced',
		'protective',
		'provoked',
		'rage',
		'reckless',
		'repugnant',
		'resentful',
		'revolted',
		'revulsion',
		'righteous',
		'riled up',
		'rude',
		'ruffled',
		'ruthless',
		'salty',
		'sassy',
		'self-deprecating',
		'self-hating',
		'self-loathing',
		'sly',
		'spiteful',
		'spun',
		'stewing',
		'sullen',
		'suspicious',
		'taken advntange of',
		'tough',
		'toxic',
		'tumultuous',
		'unforgiving',
		'unglued',
		'unimpressed',
		'unkind',
		'unreasonable',
		'unsettled',
		'upset',
		'vengeful',
		'vexed',
		'vicious',
		'vindicated',
		'violent',
		'vitriolic',
		'volative',
	],
	stress: [
		'anticipating',
		'anxious',
		'anxiety',
		'apprehensive',
		'nervous',
		'overwhelmed',
		'restless',
		'stressed',
		'uncertain',
		'worried',
	],
	fearful: [
		'abused',
		'avoidant',
		'bashful',
		'blah',
		'cautious',
		'concerned',
		'distressed',
		'distrustful',
		'doubtful',
		'dread',
		'dubious',
		'exposed',
		'fearful',
		'frightened',
		'guarded',
		'hesitant',
		'horrified',
		'hypervigilant',
		'inhibited',
		'insecure',
		'intimidated',
		'panicked',
		'paralyzed',
		'paranoid',
		'pushed',
		'scared',
		'shaken up',
		'shocked',
		'shy',
		'skeptical',
		'suspicious',
		'terrified',
		'threatened',
		'timid',
		'trapped',
		'trepidation',
		'unsafe',
		'vulnerable',
		'wary',
	],
	happy: [
		'accepted',
		'accomplished',
		'adventurous',
		'affable',
		'amazed',
		'amiable',
		'amused',
		'appreciative',
		'assured',
		'awed',
		'beautiful',
		'belonging',
		'blessed',
		'blissful',
		'boisterous',
		'bright',
		'bubbly',
		'buoyant',
		'captivated',
		'carefree',
		'centered',
		'cheerful',
		'childlike',
		'chipper',
		'comfortable',
		'complete',
		'confident',
		'connected',
		'content',
		'delighted',
		'delightful',
		'determined',
		'dignified',
		'eager',
		'ecstatic',
		'elated',
		'empowered',
		'encouraged',
		'engaged',
		'enthused',
		'enthusiastic',
		'entranced',
		'euphoric',
		'excited',
		'exhilarated',
		'exuberant',
		'fabulous',
		'focused',
		'free',
		'fulfilled',
		'full',
		'giddy',
		'glad',
		'good',
		'grateful',
		'great',
		'grounded',
		'happy',
		'harmonious',
		'heartened',
		'hopeful',
		'impressed',
		'indebted',
		'inspired',
		'interested',
		'intimate',
		'jazzed',
		'jovial',
		'joyful',
		'joyous',
		'jubilant',
		'liberated',
		'light',
		'lighthearted',
		'loved',
		'motivated',
		'open',
		'optimistic',
		'outstanding',
		'overjoyed',
		'playful',
		'pleasant',
		'pleased',
		'positive',
		'powerful',
		'productive',
		'protected',
		'proud',
		'pumped',
		'purposeful',
		'radiant',
		'receptive',
		'resourceful',
		'respected',
		'satisfied',
		'secure',
		'self-assured',
		'self-love',
		'serene',
		'silly',
		'spirited',
		'spontaneous',
		'stoked',
		'supported',
		'tender',
		'terrific',
		'thankful',
		'thrilled',
		'tranquil',
		'trusting',
		'upbeat',
		'uplifted',
		'valued',
		'warm',
		'whole',
		'worthy',
		'yay',
	],
	unsure: [
		'alive',
		'better',
		'blah',
		'bored',
		'busy',
		'confused',
		'flat',
		'lazy',
		'meh',
		'uneasy',
		'unsure',
	],
	neutral: [
		'adequate',
		'aloof',
		'ambivalent',
		'apathetic',
		'detached',
		'distant',
		'fair',
		'fine',
		'flexible',
		'indifferent',
		'mediocre',
		'neutral',
		'ok',
		'okay',
		'still',
		'unfeeling',
	],
	peaceful: [
		'balanced',
		'calm',
		'chill',
		'easy-going',
		'Loving',
		'mellow',
		'peaceful',
		'refreshed',
		'rejuvenated',
		'relaxed',
		'relieved',
	],
	physical: [
		'drained',
		'energetic',
		'energized',
		'exhausted',
		'fatigued',
		'ill',
		'lethargic',
		'pain',
		'Pained',
		'sick',
		'sluggish',
		'sore',
	],
	sad: [
		'abandoned',
		'alienated',
		'alone',
		'anguished',
		'ashamed',
		'awful',
		'blue',
		'bullied',
		'bummed',
		'cancerous',
		'catatonic',
		'crappy',
		'crestfallen',
		'defeated',
		'deflated',
		'dejected',
		'demoralized',
		'depressed',
		'desolate',
		'despairing',
		'despondent',
		'detestable',
		'devastated',
		'disappointed',
		'discontent',
		'discouraged',
		'disgraced',
		'dismayed',
		'dispirited',
		'distant',
		'doleful',
		'dour',
		'down',
		'downcast',
		'downtrodden',
		'dreary',
		'dysphoric',
		'embarrassed',
		'empty',
		'ennui',
		'eviscerated',
		'forgotten',
		'forlorn',
		'gloomy',
		'glum',
		'grey',
		'grief-stricken',
		'grieving',
		'grim',
		'guilty',
		'heartbroken',
		'heavy',
		'heavyhearted',
		'helpless',
		'hopeless',
		'humiliated',
		'humourless',
		'hurt',
		'ignored',
		'inadequate',
		'inconsolable',
		'inferior',
		'insignificant',
		'invisible',
		'isolated',
		'left out',
		'lifeless',
		'listless',
		'lonely',
		'lost',
		'low',
		'melancholy',
		'miserable',
		'misguided',
		'misunderstood',
		'mopey',
		'morose',
		'mortified',
		'mournful',
		'neglected',
		'numb',
		'pensive',
		'pessimistic',
		'pitiful',
		'powerless',
		'regretful',
		'rejected',
		'remorseful',
		'resigned',
		'ridiculed',
		'sad',
		'self-pity',
		'sensitive',
		'shameful',
		'shitty',
		'somber',
		'sorrowful',
		'stuck',
		'suicidal',
		'tearful',
		'tormented',
		'tortured',
		'troubled',
		'ugh',
		'uncomfortable',
		'underwhelmed',
		'unhappy',
		'unloved',
		'unwanted',
		'useless',
		'victimized',
		'violated',
		'wistful',
		'withdrawn',
		'worthless',
		'wounded',
		'wretched',
	],
	sleep: [
		'awake',
		'knackered',
		'lethargic',
		'rested',
		'sleepy',
		'spent',
		'tired',
		'wiped',
	],
};
export default emotionList;
