import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import BarChart from '../BarChart';
import { getSectionSummary } from '../../pages/getSectionSummary';
import { getDates } from '../../pages/getDates';

function OneLineComparison({ data, section }) {
	const { t } = useTranslation();
	const { baselineDate, exitDate } = getDates(data);
	const { baseline, exit } = getSectionSummary(data, section);

	// The one line does not need the label
	delete baseline[0].label;
	delete exit[0].label;

	return (
		<div className="row challenge-dates">
			<div className="col-half">
				<h3>{t('components:oneLineComparison.baseline')}: ({baselineDate})</h3>
				<div style={{ height: 50, width: 300 }}>
					<BarChart data={baseline} marginLeft={20} scaleOnBottom={false} />
				</div>
			</div>
			<div className="col-half">
				<h3>{t('components:oneLineComparison.current')}: ({exitDate})</h3>
				<div style={{ height: 50, width: 300 }}>
					<BarChart data={exit} marginLeft={20} scaleOnBottom={false} />
				</div>
			</div>
		</div>
	);
}

OneLineComparison.propTypes = {
	data: PropTypes.shape(PropTypes.array.isRequired).isRequired,
	section: PropTypes.string.isRequired,
};

export default OneLineComparison;
