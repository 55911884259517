import gql from 'graphql-tag';

const graph = {
	GET_MINDFULNESSES: gql`
		query GetMindfulnesses {
			mindfulnesses {
				id
				cmsId
				owner {
					id
					name
				}
				rating
				comment
				completedDate
				contentExercise {
					id
					type
				}
			}
		}
	`,
};

export default graph;
