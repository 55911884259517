import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './References.scss';

function References({ pageNumber }) {
	const { t } = useTranslation();

	return (
		<article className={`references ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={t('pages:references.title')} />
				<div className="report-content">
					<p>
						Baumeister, R. F., Vohs, K. D., Aaker, J. L., &amp; Garbinsky, E. N. (2013). Some key differences between a happy life and a meaningful life.{' '}
						<span>The journal of positive psychology</span>, 8(6), 505-516.
					</p>
					<p>
						Berghoff, C. R., Wheeless, L. E., Ritzert, T. R., Wooley, C. M., &amp; Forsyth, J. P. (2017). Mindfulness meditation adherence in a college sample: comparison of a 10-min versus 20-min
						2-weekdaily practice. <span>Mindfulness</span>, 8(6), 1513-1521.
					</p>
					<p>
						Bostock, S., Crosswell, A. D., Prather, A. A., &amp; Steptoe, A. (2019). Mindfulness on-the-go: Effects of a mindfulness meditation app on work stress and well-being.
						<span>Journal of occupational health psychology</span>, 24(1), 127.
					</p>
					<p>
						Brown, K. W., &amp; Ryan, R. M. (2003). The benefits of being present: mindfulness and its role in psychological well-being.
						<span>Journal of personality and social psychology</span>, 84(4), 822.
					</p>
					<p>
						Deci, E. L., &amp; Ryan, R. M. (2008). Hedonia, eudaimonia, and well-being: An introduction.
						<span>Journal of happiness studies</span>, 9(1), 1-11.
					</p>
					<p>
						Diener, E. D., Emmons, R. A., Larsen, R. J., &amp; Griffin, S. (1985). The satisfaction with life scale. <span>Journal of personality assessment</span>, 49(1), 71-75.
					</p>
					<p>
						Grant, A. M., Franklin, J., &amp; Langford, P. (2002). The self-reflection and insight scale: A new measure of private self-consciousness.{' '}
						<span>Social Behavior and Personality: an international journal</span>, 30(8), 821-835.
					</p>
					<p>
						Lyke, J. A. (2009). Insight, but not self-reflection, is related to subjective well-being. <span>Personality and Individual Differences</span>, 46(1), 66-70.
					</p>
					<p>
						Maher, J. P., Pincus, A. L., Ram, N., &amp; Conroy, D. E. (2015). Daily physical activity and life satisfaction across adulthood.
						<span>Developmental psychology</span>, 51(10), 1407
					</p>
					<p>
						Pavot, W., &amp; Diener, E. (2008). The satisfaction with life scale and the emerging construct of life satisfaction. <span>The journal of positive psychology</span>, 3(2), 137-152.
					</p>
					<p>
						Pavot, W., &amp; Diener, E. (2013). Happiness experienced: The science of subjective well-being. <span>The Oxford handbook of happiness</span>, 134-151.
					</p>
					<p>
						Pressman, S. D., Matthews, K. A., Cohen, S., Martire, L. M., Scheier, M., Baum, A., &amp; Schulz, R. (2009). Association of enjoyable leisure activities with psychological and physical
						well-being. <span>Psychosomatic medicine</span>, 71(7), 725.
					</p>
					<p>
						Scheier, M. F., Wrosch, C., Baum, A., Cohen, S., Martire, L. M., Matthews, K. A., ... &amp; Zdaniuk, B. (2006). The life engagement test: Assessing purpose in life.{' '}
						<span>Journal of behavioral medicine</span>, 29(3), 291.
					</p>
					<p>
						Tennant, R., Hiller, L., Fishwick, R., Platt, S., Joseph, S., Weich, S., ... &amp; Stewart-Brown, S. (2007). The Warwick-Edinburgh mental well-being scale (WEMWBS): development and UK
						validation.
						<span>Health and Quality of life Outcomes</span>, 5(1), 63.
					</p>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

References.propTypes = {
	pageNumber: PropTypes.number.isRequired,
};

export default References;
