import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import { dataShape } from '../../shapes/dataShape';
import './WellBeingIndex.scss';
import BarChart from '../../components/BarChart';
import BarChartDelta from '../../components/BarChartDelta';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function WellBeingIndex({ data, pageNumber, sort }) {
	const { t } = useTranslation();

	const { survey } = data;
	// TODO: translate survey data labels
	const { data: dataBaseline } = survey.baseline;
	const { data: dataExit } = survey.exit;
	const { delta: dataDelta } = survey;

	const baselineDate = data.survey.baseline.date.shortDate;
	const exitDate = data.survey.exit.date.shortDate;

	if (sort) {
		dataBaseline.sort((a, b) => a.value - b.value);
		dataExit.sort((a, b) => a.value - b.value);
		dataDelta.sort((a, b) => a.value - b.value);
	}

	return (
		<article className={`well-being-index ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={t('pages:wellBeingIndex.title')} />
				<div className="report-content">
					<div className="row challenge-dates">
						<div className="col-half" style={{ height: 300, width: 'auto' }}>
							<h3>{t('common:baseline')}: ({baselineDate})</h3>
							<BarChart data={dataBaseline} marginLeft={120} marginTop={20} />
						</div>
						<div className="col-half" style={{ height: 300, width: 'auto' }}>
							<h3>{t('common:current')}: ({exitDate})</h3>
							<BarChart data={dataExit} marginLeft={120} marginTop={20} />
						</div>
					</div>
					<div className="overview">
						<p>
							{t('pages:wellBeingIndex.yourWellBeingIndexShownAbove')}
						</p>
					</div>
				</div>
				<div className="bottom">
					<div className="row">
						<div className="col-half" style={{ height: 300, width: 'auto' }}>
							<h3>{t('pages:wellBeingIndex.changesInYourWellBeing')}</h3>
							<BarChartDelta data={dataDelta} />
						</div>
						<div className="col-half">
							<div className="index-reflection">
								<p>
									{t('pages:wellBeingIndex.throughoutThe30DayChallenge')}
								</p>
								<p>
									<span>{t('common:reflection')}:</span> {t('pages:wellBeingIndex.takeAMomentToReflect')}
								</p>
							</div>
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

WellBeingIndex.defaultProps = {
	sort: false,
};

WellBeingIndex.propTypes = {
	data: dataShape.isRequired,
	pageNumber: PropTypes.number.isRequired,
	sort: PropTypes.bool,
};

export default WellBeingIndex;
