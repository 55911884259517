const categoryColours = {
	happy: '#F37223',
	peaceful: '#EF493D',
	physical: '#82c55c',
	angry: '#CC6A4E',
	stress: '#EF3F66',
	fearful: '#F68E4D',
	sad: '#626290',
	sleep: '#B84D8B',
	unsure: '#F26C63',
	neutral: '#EDBD1A',
	other: '#428c94',
};
export default categoryColours;
