import React from 'react';
import CardHeader from '../CardHeader';
import { useTranslation } from '../../hooks/useTranslation';

export default function NotFound() {
	const { t } = useTranslation();

	return (
		<section>
			<div className="container">
				<div className="card text-center">
					<CardHeader title="Oops!" />
					<div className="card-body">
						<div className="mt-auto mb-auto">
							<div>
								<h5 className="card-text">{t('components:notFound.sorryWeCouldNotFind')}</h5>
								<p className="card-text">
									{t('components:notFound.areYouTryingTo')}
									<a href="/"> {t('components:notFound.checkIn')}</a>
									?
								</p>
								<p className="card-text">
									{t('components:notFound.areYouTryingTo')}
									<a href="/checkins"> {t('components:notFound.seeCheckIns')}</a>
									?
								</p>
								<p className="card-text">
									{t('components:notFound.areYouTryingTo')}
									<a href="/invite"> {t('components:notFound.inviteAFriend')}</a>
									?
								</p>
							</div>
							<div className="sexy_line" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
