import { useQuery } from '@apollo/react-hooks';
import checkinGraph from '../../graphs/checkin';

export function useCheckin() {
	const { loading, error, data } = useQuery(checkinGraph.GET_CHECKINS, {
		fetchPolicy: 'cache-and-network',
	});
	return { loading, data, error };
}

export function useSharedCheckin(email) {
	const { loading, error, data } = useQuery(checkinGraph.GET_SHAREDCHECKINS, {
		variables: { request: { email } },
		fetchPolicy: 'cache-and-network',
	});

	return { loading, data, error };
}
