import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import './Footer.scss';
import logo from '../../assets/CheckingIn-Logo-Yellow-White.png';

function Footer({ pageNumber }) {
	const { t } = useTranslation();

	return (
		<div className="footer">
			<span className="footer-link">checkingin.co</span>
			<img src={logo} height="40px" alt={t('components:footer.logoAlt')} />
			<span className="pageNumber">{pageNumber}</span>
		</div>
	);
}
Footer.propTypes = { pageNumber: PropTypes.number.isRequired };

export default Footer;
