export const checkingIn = {
	axis: {
		domain: {
			line: {
				stroke: '#ffffff',
				strokeWidth: 0,
				fill: 'none',
			},
		},
	},
	grid: {
		line: {
			stroke: '#dddddd',
			strokeWidth: 1,
			fill: 'none',
		},
	},
};
