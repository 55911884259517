import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';

const PieChartSummary = ({ data }) => {
	const configBarChart = {
		isInteractive: false,
		padding: 0.3,
		data,
		margin: {
			top: 20,
			right: 20,
			bottom: 50,
			left: 20,
		},
		enableRadialLabels: false,
		enableSliceLabels: false,
		innerRadius: 0.7,
		colors: (bar) => {
			const { color } = data.find(d => d.id === bar.id);
			return color;
		},
	};

	return <ResponsivePie {...configBarChart} />;
};

PieChartSummary.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default PieChartSummary;
