import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from '../useTranslation';
import { calculateDelta } from './calculateDelta';
import { calculateSurveyResults } from './calculateSurveyResults';
import { ReportDate } from './ReportDate';

export function useSurveydata(userId) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		setLoading(true);
		const getReportData = async (id) => {
			const WBSAPI = process.env.REACT_APP_API_WBS_SURVEY;
			let apiResult = {};
			try {
				apiResult = await axios.get(`${WBSAPI}report-integration/${id}`);
			} catch (error) {
				console.log('Uh-Oh', Object.keys(error), error.message);
			}
			if (!('data' in apiResult) || !('baseline' in apiResult.data) || !apiResult.data.baseline.length) {
				setData({});
				setLoading(false);
				return;
			}

			const baselineSurvey = apiResult.data.baseline[0];
			const exitSurvey = apiResult.data.exit[0];

			const dataBaseline = calculateSurveyResults(baselineSurvey, t);
			const dataExit = exitSurvey ? calculateSurveyResults(exitSurvey, t) : [];
			const dataDelta = exitSurvey ? calculateDelta(dataBaseline, dataExit) : [];

			const survey = {
				baseline: {
					date: ReportDate(moment(baselineSurvey.created_at.substr(0, 10))),
					data: dataBaseline,
				},
				exit: {
					date: exitSurvey ? ReportDate(moment(exitSurvey.created_at.substr(0, 10))) : null,
					data: dataExit,
				},
				delta: dataDelta,
			};
			setData(survey);
			setLoading(false);
		};
		getReportData(userId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	return { loading, data };
}
