import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { dataShape } from '../../shapes/dataShape';
import './Cover.scss';
import logo from '../../assets/CheckingIn-Logo-Yellow-White.png';

function Cover({ data }) {
	const { t } = useTranslation();
	const { longDate: date } = data.date;
	const { user } = data.user;
	const { name } = user;

	// TODO: translate date
	return (
		<article className="cover">
			<section className="page">
				<div className="header">
					<h1>{t('pages:cover.title')}</h1>
					<h2>
						{name}
						<span className="header-separator"> | </span>
						<span className="header-date">{date}</span>
					</h2>
				</div>
				<div className="content" />
				<div className="footer">
					<img src={logo} height="80px" alt={t('pages:cover.imageAlt')} />
				</div>
			</section>
		</article>
	);
}

Cover.propTypes = {
	data: dataShape.isRequired,
};

export default Cover;
