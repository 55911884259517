import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './TableOfContents.scss';

function TableOfContents({ pageNumber }) {
	const { t } = useTranslation();

	return (
		<article className={`table-of-contents ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={t('pages:tableOfContents.title')} />
				<div className="report-content">
					<div className="index-wrapper">
						<div className="row">
							<h3>{t('pages:tableOfContents.wellBeingIndex')}</h3>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.executiveSummary')}</div>
							<div className="dots" />
							<div className="number">4</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.wellBeing')}</div>
							<div className="dots" />
							<div className="number">5</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.lifeHappinessAndLifeMeaning')}</div>
							<div className="dots" />
							<div className="number">6</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.lifeSatisfaction')}</div>
							<div className="dots" />
							<div className="number">7</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.lifeEngagement')}</div>
							<div className="dots" />
							<div className="number">8</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.selfReflectionAndSelfInsight')}</div>
							<div className="dots" />
							<div className="number">9</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.mindfulness')}</div>
							<div className="dots" />
							<div className="number">10</div>
						</div>
					</div>
					<div className="index-wrapper">
						<div className="row">
							<h3>{t('pages:tableOfContents.30DayChallenge')}</h3>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.executiveSummary')}</div>
							<div className="dots" />
							<div className="number">11</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.wordCloud')}</div>
							<div className="dots" />
							<div className="number">12</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.wordsCategorized')}</div>
							<div className="dots" />
							<div className="number">12</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.dailyAverageEnergyLevel')}</div>
							<div className="dots" />
							<div className="number">13</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.timeOfCheckIns')}</div>
							<div className="dots" />
							<div className="number">13</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.mindfulnessExercises')}</div>
							<div className="dots" />
							<div className="number">14</div>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.reflectionExercises')}</div>
							<div className="dots" />
							<div className="number">14</div>
						</div>
					</div>
					<div className="index-wrapper">
						<div className="row">
							<h3>{t('pages:tableOfContents.goingForward')}</h3>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.whatsNext')}</div>
							<div className="dots" />
							<div className="number">15</div>
						</div>
					</div>
					<div className="index-wrapper">
						<div className="row">
							<h3>{t('pages:tableOfContents.references')}</h3>
						</div>
						<div className="row">
							<div className="title">{t('pages:tableOfContents.scholarlyReferences')}</div>
							<div className="dots" />
							<div className="number">16</div>
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

TableOfContents.propTypes = {
	pageNumber: PropTypes.number.isRequired,
};

export default TableOfContents;
