import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import WordCloud from '../../components/WordCloud';
import WordCategorizedBarChart from '../../components/WordCategorizedBarChart';
import './WordCloudCategorized.scss';
import emotionList from '../../assets/data/emotionList';
import categoryColours from '../../assets/data/category-colours';
import { dataShape } from '../../shapes/dataShape';

function getWordCloudFromCheckins(checkins, translation) {
	const wordArrayAll = checkins.map((c) => c.feelingWord);
	const allWords = wordArrayAll.map((value) => value.toLowerCase());
	const eachWordFrequency = allWords.reduce((acc, curr) => {
		if (typeof acc[curr] === 'undefined') {
			acc[curr] = 1;
		} else {
			acc[curr] += 1;
		}
		return acc;
	}, {});

	const words = Object.keys(eachWordFrequency).map((key) => ({
		text: translation(`emotionList:${key}`),
		value: eachWordFrequency[key],
	}));
	return words;
}

function getWordCategorized(checkIns, translation) {
	const wordArrayAll = checkIns.map((c) => c.feelingWord);
	// all feeling words used in lower case
	const allWords = wordArrayAll.map((value) => value.toLowerCase());
	// occurence of each word used
	const eachWordFrequency = allWords.reduce((acc, curr) => {
		if (typeof acc[curr] === 'undefined') {
			acc[curr] = 1;
		} else {
			acc[curr] += 1;
		}
		return acc;
	}, {});
	// occurence of each emotion category
	let wordMappedCount = 0;
	const categoryTally = Object.entries(emotionList).reduce((result, [category, emotions]) => {
		const resultCopy = result;
		resultCopy[category] = emotions.reduce((sum, emotion) => {
			if (!eachWordFrequency[emotion]) {
				return sum;
			}
			wordMappedCount += eachWordFrequency[emotion];
			return sum + (eachWordFrequency[emotion] || 0);
		}, 0);
		return resultCopy;
	}, {});
	categoryTally.other = allWords.length - wordMappedCount;

	const wordCloudChartData = [];
	Object.entries(categoryTally).map((e) => {
		const value = e[1] !== 0 ? Math.round(Number(((e[1] / checkIns.length) * 100).toFixed(2))) : 0;
		return wordCloudChartData.push({
			label: translation(`words:${e[0]}`),
			id: e[0],
			[e[0]]: value,
			value,
			color: categoryColours[e[0]],
			checkins: Number(e[1]),
		});
	});
	const words = wordCloudChartData.sort((a, b) => a.value - b.value);

	let tickValues;
	if (window.innerWidth < 768) {
		const half = Number(words[words.length - 1].value / 2).toFixed(0);
		const last = Number(words[words.length - 1].value).toFixed(0);
		tickValues = [0, half, last];
	}

	return { words, tickValues };
}

function WordCloudCategorized({ data, pageNumber, days }) {
	const { t } = useTranslation();
	const words = getWordCloudFromCheckins(data.checkins, t);
	const dataWordsCategorized = getWordCategorized(data.checkins, t);

	let headerTitle = t('pages:wordCloudCategorized.headerTitle');
	let theEmotionsYouRecorded = t('pages:wordCloudCategorized.theEmotionsYouRecorded');
	let theFigureToTheLeftShows = t('pages:wordCloudCategorized.theFigureToTheLeftShows');

	if (days) {
		headerTitle = headerTitle.replaceAll('30', days);
		theEmotionsYouRecorded = theEmotionsYouRecorded.replaceAll('30', days);
		theFigureToTheLeftShows = theFigureToTheLeftShows.replaceAll('30', days);
	}

	return (
		<article className={`word-cloud-categorized ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={headerTitle} />
				<div className="report-content">
					<div className="introduction">
						<div className="row">
							<div className="col-half">
								<div>
									<h3>{t('pages:wordCloudCategorized.title')}</h3>
									<p>{theEmotionsYouRecorded}</p>
									<p>
										<span>{t('common:reflection')}:</span> {t('pages:wordCloudCategorized.doYouFindThatYouReported')}
									</p>
								</div>
							</div>
							<div className="col-half">
								<WordCloud words={words} />
							</div>
						</div>
					</div>
				</div>
				<div className="bottom">
					<div className="row">
						<div className="col-half" style={{ height: 350, width: 350 }}>
							<WordCategorizedBarChart data={dataWordsCategorized} />
						</div>
						<div className="col-half">
							<h3>{t('pages:wordCloudCategorized.wordsCategorized')}</h3>
							<p>{theFigureToTheLeftShows}</p>
							<p>
								<span>{t('common:reflection')}:</span> {t('pages:wordCloudCategorized.lookingAtTheseCategories')}
							</p>
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

WordCloudCategorized.propTypes = {
	data: dataShape.isRequired,
	pageNumber: PropTypes.number.isRequired,
};

export default WordCloudCategorized;
