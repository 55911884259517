import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

function usePublication(id) {
	const { loading, error, data } = useQuery(
		gql`
			query PublicationRecord($id: ID) {
				publicationRecordById(id: $id) {
					id
					name
					scope
					restrictedTo
					challengeDays
					challengeStartDate
					type
					origins {
						id
						originId
					}
				}
			}
		`,
		{
			variables: { id },
			fetchPolicy: 'cache-and-network',
		}
	);

	return { loading, data, error };
}

const ReportSelector = () => {
	const location = useLocation();
	const publicationId = new URLSearchParams(location.search).get('publicationId');
	const { loading, data, error } = usePublication(publicationId);
	const [report, setReport] = useState();
	useEffect(() => {
		if (!loading && data && data?.publicationRecordById) {
			const challengeStartDate = data.publicationRecordById.challengeStartDate?.substring(0, 10) ?? null;
			if (data.publicationRecordById.challengeDays) {
				setReport(`/challengereport/${data.publicationRecordById.challengeDays}${challengeStartDate ? `/${challengeStartDate}` : ''}`);
			} else {
				setReport('/report');
			}
		}
	}, [loading, data, error]);

	return <>{report && <Redirect to={report} />}</>;
};

export default ReportSelector;
