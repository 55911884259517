import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import i18n from '../../i18n';
import { dataShape } from '../../shapes/dataShape';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BarChart from '../../components/BarChart';
import './LifeHappiness.scss';
import { CONSTANTS } from '../../constants';
import { getSectionSummary } from '../getSectionSummary';
import { getDates } from '../getDates';

function LifeHappiness({ data }) {
	const { t } = useTranslation();
	const { baselineDate, exitDate } = getDates(data);
	const { baseline: dataLifeHappinessBaseline, exit: dataLifeHappinessCurrent } = getSectionSummary(data, CONSTANTS.LIFE_HAPPINESS.key);
	const { baseline: dataLifeMeaningBaseline, exit: dataLifeMeaningCurrent } = getSectionSummary(data, CONSTANTS.LIFE_MEANING.key);

	const { color, pageNumber } = CONSTANTS.LIFE_MEANING_HAPPINESS;

	return (
		<article className={`life-happiness-meaning ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<Header title={t('pages:lifeHappiness.title')} color={color} />
				<div className={`report-content ${i18n.language === 'fr-CA' && 'french'}`} id="life-happiness-meaning">
					<div className="row challenge-dates">
						<div className="col-half">
							<h3>{t('common:baseline')}: ({baselineDate})</h3>
							<div style={{ height: 50, width: 300 }}>
								<BarChart data={dataLifeHappinessBaseline} marginLeft={95} scaleOnBottom={false} />
								<BarChart data={dataLifeMeaningBaseline} marginLeft={95} scaleOnBottom={false} />
							</div>
						</div>
						<div className="col-half">
							<h3>{t('common:current')}: ({exitDate})</h3>
							<div style={{ height: 50, width: 300 }}>
								<BarChart data={dataLifeHappinessCurrent} marginLeft={95} scaleOnBottom={false} />
								<BarChart data={dataLifeMeaningCurrent} marginLeft={95} scaleOnBottom={false} />
							</div>
						</div>
					</div>
					<div className="overview">
						<h3>{t('common:overview')}</h3>
						<p>
							{t('pages:lifeHappiness.lifeMeaningAndLifeHappinessAreTwoIndicators')}
						</p>
						<p>
							{t('pages:lifeHappiness.researchByBaumeister')}
						</p>
						<p>
							{t('pages:lifeHappiness.happinessAndMeaningOftenOverlap')}
						</p>
					</div>
				</div>
				<div className="bottom">
					<div className="row">
						<div className="col-half">
							<h3>{t('pages:lifeHappiness.checkingInAndLifeHappiness')}</h3>
							<p>
								{t('pages:lifeHappiness.checkingInEncouragesYou')}
							</p>
						</div>
						<div className="col-half reflection">
							<h3>{t('common:reflection')}</h3>
							<p>
								{t('pages:lifeHappiness.considerYourOwnFeelings')}
							</p>
						</div>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

LifeHappiness.propTypes = {
	data: dataShape.isRequired,
};

export default LifeHappiness;
