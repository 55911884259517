import gql from 'graphql-tag';

const graph = {
	UPDATE_USER: gql`
				mutation UpdateUser($request: UpdateUserRequest!) {
						updateUser(user: $request) {
								id
								name
								createdDate
						}
				}
		`,
	GET_USER: gql`
				query GetUserAndCheckInsStats($request: CheckInStatsRequest!) {
						user {
								id
								name
								createdDate
								subscriptionType
						}
						stats(params: $request) {
								currentStreak
								longestStreak
								daysCheckedIn
								count
								hasMorning
								hasAfternoon
								hasEvening
								weekDayIndexes
						}
				}
		`,
	GET_IMPERSONATED_USER: gql`
			query GET_IMPERSONATED_USER($request: ImpersonateUser!) {
				user(where: $request) {
					id
					name
					createdDate
				}

			}
`,
	GET_USER_SETTINGS: gql`
			query GetUserSettings($input: UserSettingFilterInput) {
				userSetting(params: $input) {
					id
					meta
					active
					type
					platform
				}
			}
`,
};

export default graph;
