/* eslint-disable indent */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';
import { useTranslation } from '../../hooks/useTranslation';
import { checkingIn } from '../../utils/charts/theme';

const AverageEnergyBarChart = ({ challengeStart, checkinDays, days: numberOfDaysToDisplay }) => {
	const { t } = useTranslation();
	// bar charts data
	const enumerateDaysBetweenDates = (startDate, endDate) => {
		const dates = [];

		const currDate = moment(startDate).startOf('day').subtract(1, 'day');
		const lastDate = moment(endDate).startOf('day').add(1, 'day');

		while (currDate.add(1, 'day').diff(lastDate) < 0) {
			dates.push(moment(currDate.clone()).format('YYYYMMDD'));
		}
		return dates;
	};
	const allDays = enumerateDaysBetweenDates(challengeStart, moment(challengeStart).add(numberOfDaysToDisplay, 'days'));
	const avgDailyEnergy = checkinDays.map((d) => Number(d.avg));
	let averageEnergyBarChartData = [];
	avgDailyEnergy.map((e, i) => averageEnergyBarChartData.push({ Energy: e, date: checkinDays[i].date, day: moment(checkinDays[i].date).format('DD') }));
	// fill missing dates
	allDays.forEach((e) => {
		let found = false;
		for (let i = 0; i < averageEnergyBarChartData.length; i++) {
			if (averageEnergyBarChartData[i].date === e) {
				found = true;
				break;
			}
		}
		if (!found) {
			averageEnergyBarChartData = averageEnergyBarChartData.concat({ Energy: 0, date: e, day: moment(e).format('DD') });
		}
	});
	averageEnergyBarChartData.sort((a, b) => a.date - b.date);

	averageEnergyBarChartData = averageEnergyBarChartData.slice(0, numberOfDaysToDisplay);

	for (let i = 0; i < averageEnergyBarChartData.length; i++) {
		averageEnergyBarChartData[i].day = String(i + 1).padStart(2, '0');
	}

	return (
		<ResponsiveBar
			data={averageEnergyBarChartData}
			keys={['Energy']}
			fill={[{ match: { id: 'Energy' }, id: 'gradientEnergy' }]}
			indexBy="day"
			margin={{
				top: 50,
				right: 60,
				bottom: 50,
				left: 60,
			}}
			maxValue={10}
			groupMode="grouped"
			colors={{ scheme: 'nivo' }}
			defs={[
				{
					id: 'gradientEnergy',
					type: 'linearGradient',
					colors: [
						{ offset: 0, color: '#E6B92A' },
						{ offset: 100, color: '#E78164' },
					],
				},
			]}
			borderColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
			axisTop={null}
			axisRight={null}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: t('components:other.energyLevel'),
				legendPosition: 'middle',
				legendOffset: -40,
			}}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			enableLabel={false}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor="#FFF"
			animate={false}
			motionStiffness={90}
			motionDamping={15}
			theme={checkingIn}
		/>
	);
};
AverageEnergyBarChart.propTypes = {
	checkinDays: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	challengeStart: PropTypes.string.isRequired,
};
export default AverageEnergyBarChart;
