import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../hooks/useTranslation';
import { dataShape } from '../../shapes/dataShape';
import Footer from '../../components/Footer';
import i18n from '../../i18n';
import './CongratulationsNoSurvey.scss';
import logo from '../../assets/checkingin-logo-yellow-teal.png';

function CongratulationsNoSurvey({ data, pageNumber, days }) {
	const { t } = useTranslation();
	const { user } = data.user;
	const { name } = user;
	let sucessMessage = t('pages:congratulations.successfullyCompleted30Day');
	if (days) {
		sucessMessage = sucessMessage.replaceAll('30', days);
	}

	return (
		<article className={`congratulations ${i18n.language === 'fr-CA' && 'french'}`}>
			<section className="page">
				<div className="report-content">
					<img src={logo} height="110px" alt="Checking In logo header" />
					<p>
						{t('pages:congratulations.title')}, {name}!
					</p>
					<p>{sucessMessage}</p>
					<p>{t('pages:congratulations.reportIntendedToOfferInsight')}</p>
					<p>{t('pages:congratulations.reportIsOnlyABriefSnapshot')}</p>
					<p>{t('pages:congratulations.yourReportIsCompletelyConfidentialNoSurvey')}</p>
					<div className="signature">
						<p>{t('pages:congratulations.sincerely')},</p>
						<p className="ceo-signature">Sean Burke</p>
						<p>
							{t('pages:congratulations.ceoFounder')}
							<br />
							CheckingIn Software Ltd.
						</p>
					</div>
				</div>
				<Footer pageNumber={pageNumber} />
			</section>
		</article>
	);
}

CongratulationsNoSurvey.propTypes = {
	data: dataShape.isRequired,
	pageNumber: PropTypes.number.isRequired,
};

export default CongratulationsNoSurvey;
