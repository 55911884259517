import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Spinner from '../../components/Spinner';
import { useCheckin } from '../../hooks/checkin/queries';
import { useMindfulness } from '../../hooks/mindfulness/queries';
import { useReflections } from '../../hooks/reflection/queries';
import { useUser, useUserSettings } from '../../hooks/user/queries';
import { useTranslation } from '../../hooks/useTranslation';
import { ReportDate } from '../../hooks/well-being/ReportDate';
import { useSurveydata } from '../../hooks/well-being/useSurveydata';
import { userShape } from '../../shapes/userShape';
import Congratulations from '../Congratulations';
import Cover from '../Cover';
import EnergyLevel from '../EnergyLevel';
import ExecutiveSummary from '../ExecutiveSummary';
import GoingForward from '../GoingForward';
import LifeEngagement from '../LifeEngagement';
import LifeHappiness from '../LifeHappiness';
import LifeSatisfaction from '../LifeSatisfaction';
import MeditationsReflections from '../MeditationsReflections';
import Mindfulness from '../Mindfulness';
import References from '../References';
import SelfReflection from '../SelfReflection';
import TableOfContents from '../TableOfContents';
import WellBeing from '../WellBeing';
import WellBeingIndex from '../WellBeingIndex';
import WordCloudCategorized from '../WordCloudCategorized';

const mailchimpIdClaim = 'https://web.checkingin.co/mailchimpId';

function FilterCheckinInData(allCheckIns, allReflections, allMindfulnesses, baselineDate) {
	const baselinePlus30 = moment(baselineDate).add(31, 'days'); // 31 to be inclusive
	const checkinsFiltered = allCheckIns.filter((c) => {
		const cd = moment(c.createdDate).format('YYYYMMDD');
		return cd >= moment(baselineDate).format('YYYYMMDD') && cd <= moment(baselinePlus30).format('YYYYMMDD');
	});

	const checkins = checkinsFiltered.map((e) => {
		const localDate = moment(e.createdDate).local().format('YYYYMMDD');
		return { ...e, localDate };
	});

	const reflections = allReflections.filter((c) => {
		if (c.completedDate !== null) {
			const cd = moment(c.completedDate).format('YYYYMMDD');
			return cd >= moment(baselineDate).format('YYYYMMDD') && cd <= moment(baselinePlus30).format('YYYYMMDD');
		}
		return null;
	});

	const mindfulnesses = allMindfulnesses.filter((c) => {
		if (c.completedDate !== null) {
			const cd = moment(c.completedDate).format('YYYYMMDD');
			return cd >= moment(baselineDate).format('YYYYMMDD') && cd <= moment(baselinePlus30).format('YYYYMMDD');
		}
		return null;
	});

	return { checkins, reflections, mindfulnesses };
}

function Report({ user }) {
	const days = 30;
	const { t, switchLanguage } = useTranslation();
	const timezone = user['https://web.checkingin.co/geoip'].time_zone;
	const defaultUserId = user[mailchimpIdClaim] ? user[mailchimpIdClaim] : null;

	const [loading, setLoading] = useState(true);
	const [userId] = useState(defaultUserId);

	const { userSettings } = useUserSettings();
	const { loading: loadingUser, data: userData, error } = useUser(timezone);
	const { loading: loadingSurveyData, data: surveyData } = useSurveydata(userId);
	const { loading: loadingCheckins, error: errorCheckins, data: dataCheckins } = useCheckin();
	const { loading: loadingReflections, error: errorReflections, data: dataReflections } = useReflections();
	const { loading: loadingMindfulnesses, error: errorMindfulnesses, data: dataMindfulnesses } = useMindfulness();

	const loadUserLanguage = useCallback(() => {
		if (userSettings) {
			const res = userSettings.find((setting) => setting.type === 'Preferences');
			if (res && res.meta && Array.isArray(res.meta)) {
				const userLanguage = res.meta.find((data) => data.name === 'language');
				if (userLanguage) {
					const selectedLanguage = userLanguage.value === 'fr' ? 'fr-CA' : 'en-US';
					switchLanguage(selectedLanguage);
				}
			}
		}
	}, [userSettings, switchLanguage]);

	useEffect(() => {
		loadUserLanguage();
	}, [loadUserLanguage]);

	useEffect(() => {
		const loadingAllData = loadingUser || loadingSurveyData || loadingCheckins || loadingReflections || loadingMindfulnesses;
		// setTimeout Just to make sure we load all data
		setTimeout(setLoading(loadingAllData), 100);
	}, [loadingUser, loadingSurveyData, loadingCheckins, loadingReflections, loadingMindfulnesses]);

	const today = new Date(); // This will be the Exit createdAt

	if (error) {
		console.log('Error: ', error);
		return <div>{t('components:other.somethingWentWrong')}</div>;
	}

	if (errorCheckins || errorReflections || errorMindfulnesses) {
		console.log('errorCheckins', errorCheckins);
		console.log('errorReflections', errorReflections);
		console.log('errorMindfulnesses', errorMindfulnesses);
		return <div>Uh oh!</div>;
	}

	if (loading || !userData || !surveyData || !dataCheckins || !dataReflections || !dataMindfulnesses) {
		return <Spinner />;
	}

	// Get Executive Summary
	const { originalDate: baselineDate } = surveyData.baseline.date;
	const { checkIns: allCheckIns } = dataCheckins;
	const { reflections: allReflections } = dataReflections;
	const { mindfulnesses: allMindfulnesses } = dataMindfulnesses;

	const filteredData = FilterCheckinInData(allCheckIns, allReflections, allMindfulnesses, baselineDate);

	const data = {
		date: ReportDate(today), // Month day, year
		user: { ...userData, userId },
		survey: surveyData,
		...filteredData,
	};

	if (!data.survey || !data.checkins || !data.reflections || !data.mindfulnesses) {
		return <Spinner />;
	}

	return (
		<div className="report">
			<main>
				<Cover data={data} />
				<Congratulations data={data} pageNumber={2} />
				<TableOfContents pageNumber={3} />
				<WellBeingIndex data={data} pageNumber={4} sort />
				<WellBeing data={data} />
				<LifeHappiness data={data} />
				<LifeSatisfaction data={data} />
				<LifeEngagement data={data} />
				<SelfReflection data={data} />
				<Mindfulness data={data} />
				<ExecutiveSummary data={data} pageNumber={11} days={days} />
				<WordCloudCategorized data={data} pageNumber={12} days={days} />
				<EnergyLevel data={data} pageNumber={13} days={days} />
				<MeditationsReflections data={data} pageNumber={14} days={days} />
				<GoingForward pageNumber={15} days={days} />
				<References pageNumber={16} />
			</main>
		</div>
	);
}

Report.propTypes = {
	user: userShape.isRequired,
};

export default Report;
