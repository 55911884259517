import { CONSTANTS } from '../../constants';

function increaseOneAvg(avg) {
	return (avg === '1.00') ? 1.1 : Number(avg);
}

export function calculateSurveyResults(survey, translation) {
	// Returning defaultAvg if we cannot find the avg, this is a hack for the first few surveys that may be missing some keys
	const defaultAvg = 3;
	const calculateAvg = (group) => {
		if (!('answers' in survey)) { return defaultAvg; }
		const groupAnswers = survey.answers.find(a => a.group === group);
		if (!groupAnswers) { return defaultAvg; }
		const avg = Number(groupAnswers.answers.reduce((acc, cur) => Number(cur.answer) + acc, 0) / groupAnswers.answers.length).toFixed(2);
		return increaseOneAvg(avg);
	};

	const calculateAvgPerQuestionArray = (group, questionsToInclude) => {
		if (!('answers' in survey)) { return defaultAvg; }
		const groupAllSectionsAnswers = survey.answers.find(a => a.group === group);
		// Returning 3 if we cannot find the avg, this is a hack for the first few surveys that may be missing some keys
		if (!groupAllSectionsAnswers) { return defaultAvg; }
		const groupAnswers = groupAllSectionsAnswers.answers.filter(a => questionsToInclude.includes(Number(a.question_id)));
		const avg = Number(groupAnswers.reduce((acc, cur) => Number(cur.answer) + acc, 0) / questionsToInclude.length).toFixed(2);
		// console.log('DEBUG', group, avg, groupAnswers);
		return increaseOneAvg(avg);
	};

	const calculated = {
		[CONSTANTS.WELL_BEING.key]: calculateAvg(CONSTANTS.WELL_BEING.key),
		[CONSTANTS.SELF_REFLECTION.key]: calculateAvg(CONSTANTS.SELF_REFLECTION.key),
		[CONSTANTS.SELF_INSIGHT.key]: calculateAvg(CONSTANTS.SELF_INSIGHT.key),
		[CONSTANTS.MINDFULNESS.key]: calculateAvg(CONSTANTS.MINDFULNESS.key),
		[CONSTANTS.LIFE_SATISFACTION.key]: calculateAvg(CONSTANTS.LIFE_SATISFACTION.key),
		[CONSTANTS.LIFE_MEANING.key]: calculateAvgPerQuestionArray(CONSTANTS.LIFE_MEANING_HAPPINESS.key, [1, 2, 3]),
		[CONSTANTS.LIFE_HAPPINESS.key]: calculateAvgPerQuestionArray(CONSTANTS.LIFE_MEANING_HAPPINESS.key, [4, 5, 6]),
		[CONSTANTS.LIFE_ENGAGEMENT.key]: calculateAvg(CONSTANTS.LIFE_ENGAGEMENT.key),
	};
	// console.log('calculated', calculated);
	const allSections = Object.keys(CONSTANTS);
	// console.log('ALLSections keys', allSections);
	const result = allSections
		.filter((key) => {
			// Remove the ones not part of the calculation
			const section = CONSTANTS[key].key;
			return calculated[section];
		})
		.map((key) => {
			const section = CONSTANTS[key].key;
			if (!calculated[section]) { return {}; }
			return {
				id: section,
				[section]: calculated[section],
				value: calculated[section],
				label: translation(CONSTANTS[key].label),
				color: CONSTANTS[key].color,
			};
		});

	return result;
}
