import PropTypes from 'prop-types';
import { checkinShape } from './ckeckinShape';

export const dataShape = PropTypes.shape({
	checkIns: PropTypes.arrayOf(checkinShape),
	date: PropTypes.object,
	mindfulnesses: PropTypes.array,
	reflections: PropTypes.array,
	survey: PropTypes.object,
	user: PropTypes.object,
});
