import PropTypes from 'prop-types';

export const checkinShape = PropTypes.shape({
	createdDate: PropTypes.string,
	energyLevel: PropTypes.number,
	feelingContext: PropTypes.string,
	feelingWord: PropTypes.string,
	id: PropTypes.string,
	localDate: PropTypes.string,
	weather: PropTypes.object,
});
