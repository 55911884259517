import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from '../../hooks/useTranslation';
import { checkingIn } from '../../utils/charts/theme';

const WordCategorizedBarChart = ({ data }) => {
	const { t } = useTranslation();
	const { words, tickValues } = data;

	const configBarChart = {
		layout: 'horizontal',
		padding: 0.2,
		data: words.map((d) => {
			const valueScaled = d[d.id];
			return {
				[d.id]: valueScaled,
				label: d.label,
			};
		}),
		keys: words.reduce((acc, cur) => {
			acc.push(cur.id);
			return acc;
		}, []),
		indexBy: 'label',
		margin: {
			top: 20,
			right: 20,
			bottom: 50,
			left: 70,
		},
		valueScale: { type: 'linear' },
		enableLabel: true,
		labelTextColor: '#FFFFFF',
		enableGridX: true,
		enableGridY: false,
		axisBottom: {
			tickSize: 0,
			tickPadding: 0,
			tickRotation: 0,
			tickValues,
			legend: `${t('components:other.percent')} %`,
			legendPosition: 'middle',
			legendOffset: 32,
		},
		axisLeft: {
			tickSize: 0,
			tickPadding: 5,
			tickRotation: 0,
		},
		axisTop: null,
		axisRight: null,
		colors: (bar) => {
			const { color } = words.find(d => d.id === bar.id);
			return color;
		},
	};

	return <ResponsiveBar {...configBarChart} theme={checkingIn} />;
};

WordCategorizedBarChart.propTypes = {
	data: PropTypes.shape({
		words: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.string,
			id: PropTypes.string,
			color: PropTypes.string,
			value: PropTypes.number,
			[PropTypes.string]: PropTypes.number,
		})),
		tickValues: PropTypes.any,
	}).isRequired,
};

export default WordCategorizedBarChart;
