import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { checkingIn } from '../../utils/charts/theme';

const BarChartDelta = ({ data }) => {
	const keys = data.reduce((acc, cur) => {
		acc.push(cur.id);
		return acc;
	}, []);

	// Scaling the value to itself -1 so that the X axis represents
	// it properly as the Bar component does not allow start from 1 instead of zero
	const dataScaled = data.map((d) => {
		const valueScaled = d[d.id];
		return {
			[d.id]: valueScaled,
			label: d.label,
		};
	});
	const configBarChart = {
		layout: 'horizontal',
		padding: 0.3,
		isInteractive: false,
		data: dataScaled,
		keys,
		indexBy: 'label',
		margin: {
			top: 20,
			right: 20,
			bottom: 50,
			left: 130,
		},
		maxValue: 4,
		minValue: -4,
		valueScale: { type: 'linear' },
		enableLabel: false,
		enableGridX: true,
		enableGridY: false,
		gridXValues: 9,
		axisBottom: {
			orient: 'bottom',
			tickSize: 0,
			tickPadding: 0,
			tickRotation: 0,
			tickValues: ['-4', '-3', '-2', '-1', '0', '1', '2', '3', '4'],
		},
		axisLeft: {
			tickSize: 0,
			tickPadding: 5,
			tickRotation: 0,
		},
		axisTop: null,
		axisRight: null,
		colors: (bar) => {
			const { color } = data.find(d => d.id === bar.id);
			return color;
		},
	};

	return <ResponsiveBar {...configBarChart} theme={checkingIn} />;
};

BarChartDelta.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default BarChartDelta;
