import gql from 'graphql-tag';

const fragments = {
	checkIn: gql`
			fragment CheckInProperties on CheckIn {
				energyLevel
				feelingWord
				feelingContext
				createdDate
				weather {
						weatherId
						weatherMain
						weatherIcon
				}
			}
		`,
};

export default fragments;
